import {createRouter, createWebHashHistory, type RouteRecordRaw} from 'vue-router';

export default () => {
    const routes: Array<RouteRecordRaw> = [
        {
            path: '/transactions',
            component: () => import('./Views/RegistrationTransactions.vue'),
            props: true,
            name: 'transactions'
        },
        {
            path: '/transaction/responsible/:responsibleId',
            component: () => import('./Views/ResponsibleForm.vue'),
            props: route => ({
                ...route.params,
                ...{ responsibleId: Number(route.params.responsibleId)}
            }),
            name: 'edit-responsible',
            beforeEnter: (to, from, next) => {
                if (to.params.responsibleId) {
                    next();
                } else {
                    next({name: 'transactions'});
                }
            }
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: '/transactions'
        }
    ];

    return createRouter({
        history: createWebHashHistory(),
        routes
    });
};
