import { createPinia } from "pinia";
import { createApp } from "vue";
import { attachSentryToApp } from "@Plugins/sentry";
import i18n from '@Plugins/localization';

export const mountEventSchedule = async () => {
    const mountPoint = document.querySelector('#v-event-schedule');

    if (mountPoint) {
        const { default: EventSchedule } = await import('./Views/EventSchedule.vue');
        const templateUrl = mountPoint.getAttribute('data-template-url');
        const cssUrl = mountPoint.getAttribute('data-css-url');
        const queryUrl = mountPoint.getAttribute('data-query-url');
        const eventId = mountPoint.getAttribute('data-event-id');

        const pinia = createPinia();
        const scheduleWrapper = createApp(EventSchedule, { templateUrl, cssUrl, queryUrl, eventId });
        scheduleWrapper.use(pinia);
        scheduleWrapper.use(i18n);
        attachSentryToApp(scheduleWrapper);
        scheduleWrapper.mount(mountPoint);
    }
};

export const mountWorkshopDetail = async () => {
    const mountPoint = document.querySelector('#v-workshop-detail');

    if (mountPoint) {
        const { default: WorkshopDetail } = await import('./Views/WorkshopDetail.vue');
        const templateUrl = mountPoint.getAttribute('data-template-url');
        const cssUrl = mountPoint.getAttribute('data-css-url');
        const queryUrl = mountPoint.getAttribute('data-query-url');
        const eventId = mountPoint.getAttribute('data-event-id');
        const workshopId = mountPoint.getAttribute('data-workshop-id');

        const pinia = createPinia();
        const scheduleWrapper = createApp(WorkshopDetail, { templateUrl, cssUrl, queryUrl, eventId, workshopId });
        scheduleWrapper.use(pinia);
        scheduleWrapper.use(i18n);
        attachSentryToApp(scheduleWrapper);
        scheduleWrapper.mount(mountPoint);
    }
};

export default function mountEventScheduleModule() {
    mountEventSchedule();
    mountWorkshopDetail();
}