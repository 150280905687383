import {Theme, useTheme} from "./Common/Composables/theme";
// Plugins and filters
import i18n, { setI18nLanguage } from "@Plugins/localization";
// Modules
import mountEventScheduleModule from "./Modules/Event/Schedule/main";
import {mountTemplates} from "./Modules/Templates/template-loading";
import {mountComponents} from "./Common/Helpers/component-loader";
import {VueQueryPlugin} from "@tanstack/vue-query";
import {loadConfiguration} from "@Helpers/configuration";
import {createPinia} from "pinia";
import router from './Modules/Event/Registrations/router';

import './cms.css';
import { EntryPoint, loadSentry } from "./Plugins/sentry";
import { ConsentSubject, registerConsentCallback } from "./Modules/Consent/consent";

window.addEventListener("DOMContentLoaded", () => {
    loadConfiguration();

    const {setTheme} = useTheme();
    setTheme(Theme.CMS);

    const vueConfig = document.querySelector("#vue-config");
    if (vueConfig) {
        const locale = vueConfig.getAttribute("data-locale");
        if (locale) {
            setI18nLanguage(locale);
        }
    }

    const pinia = createPinia();

    registerConsentCallback(ConsentSubject.Sentry, () => loadSentry(EntryPoint.CMS));

    /**
     * Load the following components when detected in DOM
     */
    mountComponents({
        "calendar-link": {
            plugins: [i18n],
            component: () => import("./Common/Components/Buttons/CalendarLink.vue"),
        },
        'payment-tip': {
            plugins: [i18n],
            component: () => import('./Modules/Payment/Tip/Views/Tip.vue')
        },
        'member-my-memberships': {
            plugins: [pinia, i18n, VueQueryPlugin],
            component: () => import('./Modules/Member/MyMemberships/Views/MyMemberships.vue')
        },
        'member-organization-detail': {
            plugins: [pinia, i18n, VueQueryPlugin],
            component: () => import('./Modules/Member/OrganizationManagement/Views/OrganizationDetail.vue')
        },
        'member-organization-edit': {
            plugins: [pinia, i18n, VueQueryPlugin],
            component: () => import('./Modules/Member/OrganizationManagement/Views/OrganizationEdit.vue')
        },
        'event-registration-list': {
            plugins: [i18n, VueQueryPlugin],
            component: () => import('./Modules/Event/Registrations/Views/RegistrationList.vue')
        },
        'event-registrations-payment': {
            plugins: [i18n],
            component: () => import('./Modules/Event/Registrations/Views/RegistrationPayment.vue')
        },
        'event-registration-form': {
            plugins: [i18n, VueQueryPlugin],
            component: () => import('./Modules/Event/Registrations/Views/RegistrationForm.vue')
        },
        'event-registration-transactions': {
            plugins: [pinia, i18n, VueQueryPlugin],
            router,
            component: () => import('./Modules/Event/Registrations/Views/RegistrationRouter.vue')
        },
        'event-registration-detail': {
            plugins: [pinia, i18n, VueQueryPlugin],
            component: () => import('./Modules/Event/Registrations/Views/RegistrationDetail.vue')
        },
        'cookie-consent': {
            plugins: [i18n],
            component: () => import('./Modules/Consent/Views/CookieConsentModal.vue')
        },
        'floating-elements-queue': {
            plugins: [],
            component: () => import('./Common/Components/Notice/FloatingElementsQueue.vue')
        },
        'stripe-payment': {
            plugins: [i18n, VueQueryPlugin],
            component: () => import('./Modules/Payment/PaymentIframe/Views/StripePayment.vue')
        }
    });

    mountEventScheduleModule();

    mountTemplates();
});