import { createSharedComposable } from "@vueuse/core";
import { onBeforeMount, reactive } from "vue";

declare global {
    interface GlobalEventHandlersEventMap {
        klaroLoaded: Event;
        consentGiven: CustomEvent<ConsentSubject>;
    }
}

export enum ConsentSubject {
    Sentry = 'sentry'
};

const consentCallbacks: Record<ConsentSubject, (() => void)[]> = {
    [ConsentSubject.Sentry]: []
};

export const registerConsentCallback = (subject: ConsentSubject, callback: () => void) => {
    consentCallbacks[subject].push(callback);
};

const consentComposable = () => {
    const consent = reactive({
        [ConsentSubject.Sentry]: false
    });

    const onConsentGiven = (e: CustomEvent<ConsentSubject>) => {
        if (consent[e.detail] !== undefined) {
            consent[e.detail] = true;
            consentCallbacks[e.detail].forEach(cb => cb());
        }
    };

    onBeforeMount(() => {
        window.removeEventListener('consentGiven', onConsentGiven);
        window.addEventListener('consentGiven', onConsentGiven);
    });

    return {
        consent
    };
};

export const useConsent = createSharedComposable(consentComposable);