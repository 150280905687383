import { createApp } from "vue";
import { attachSentryToApp } from "@Plugins/sentry";
import i18n from '@Plugins/localization';

export const mountTemplates = async () => {
    const templates = document.querySelectorAll('[data-template]');

    templates.forEach(async (template) => {
        const templateUrl = template.getAttribute('data-template');
        const cssUrl = template.getAttribute('data-css-url');
        const queryUrl = template.getAttribute('data-query-url');

        const { default: Template } = await import(`./Template.vue`);
        const templateWrapper = createApp(Template, {
            templateUrl,
            cssUrl,
            queryUrl
        });
        templateWrapper.use(i18n);
        attachSentryToApp(templateWrapper);
        templateWrapper.mount(template);
    });
};